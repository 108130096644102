import React from 'react';
import { navigate } from 'gatsby';
import Section from '../../molecules/Section';
import ShowcaseItem from '../../molecules/ShowcaseItem';
import Search from '../../molecules/Search';
import Button from '../../molecules/Button';
import {
  wrapperStyles,
  Header,
  Text,
  Grid,
  SearchBar,
} from './styles/index.styled';

const Showcase = ({ component }) => {
  if (!component) return null;
  const { heading, subhead, button, showSearchBar, showcaseItems } = component;

  const handleKeyDown = (e, searchValue) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      navigate(`/partner`, { state: { search: searchValue } });
    }
  };

  const handleSubmit = (e, searchValue) => {
    e.preventDefault();
    navigate(`/partner`, { state: { search: searchValue } });
  };

  return (
    <Section wrapperStyles={wrapperStyles}>
      <Header>
        <Text>
          {heading && <h2 className="heading">{heading}</h2>}
          {subhead?.subhead && <p className="subhead">{subhead.subhead}</p>}
        </Text>
        {showSearchBar && (
          <SearchBar>
            <Search
              setSearch={() => {}}
              setSearchParam={() => {}}
              reset={() => {}}
              handleKeyDown={handleKeyDown}
              handleSubmit={handleSubmit}
            />
          </SearchBar>
        )}
      </Header>
      {showcaseItems?.length > 0 && (
        <Grid>
          {showcaseItems?.map(item => (
            <ShowcaseItem key={item?.id} {...item} />
          ))}
        </Grid>
      )}
      {button?.url && (
        <Button
          theme={button?.color}
          variant={button?.variant}
          size={button?.size}
          href={button.url}
        >
          {button.label}
        </Button>
      )}
    </Section>
  );
};

export default Showcase;

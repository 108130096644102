import React from 'react';
import shortid from 'shortid';
import PaginationNumber from './PaginationNumber';
import checkNumber from '../utils/checkNumber';
import scrollToTop from '../utils/scrollToTop';
import { Container, Elipsis } from '../styles/PaginationNumbers.styled';

const PaginationNumbers = ({
  pageNumbers,
  paginate,
  currentPage,
  nextPage,
  prevPage,
  isPrevPage,
}) => (
  <Container>
    {pageNumbers.map(number => {
      const pageNumber = number + 1;
      const showNumber = checkNumber(
        pageNumber,
        currentPage,
        pageNumbers,
        prevPage,
        nextPage,
        isPrevPage
      );
      if (!showNumber) return null;
      return (
        <PaginationNumber
          key={shortid.generate()}
          label={pageNumber}
          active={pageNumber === currentPage}
          clickEvent={() => {
            paginate(pageNumber);
            scrollToTop();
          }}
        />
      );
    })}
    <Elipsis className="text-md-bold gray-900">...</Elipsis>
    <PaginationNumber
      label={pageNumbers.length}
      active={currentPage === pageNumbers.length}
      clickEvent={() => {
        paginate(pageNumbers.length);
        scrollToTop();
      }}
    />
  </Container>
);

export default PaginationNumbers;

import styled from 'styled-components';
import { font } from '../../../atoms/typography';
import { colors } from '../../../atoms/colors';

export const Frame = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
export const Icon = styled.div`
  width: 56px;
  height: 56px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
export const Text = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  .heading {
    ${font('text', 'xl', '900')}
    color: ${colors.common.black};
  }
  .subhead {
    ${font('text', 'lg', '400')}
    color: ${colors.gray[500]};
  }
`;

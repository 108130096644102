import React from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

import Section from '../../molecules/Section';
import Form from '../../molecules/AirtableForm';

import { sectionStyles, wrapperStyles } from './styles/index.styled';

const AirtableForm = ({ component }) => {
  if (!component) return null;
  const {
    heading,
    disclaimer,
    airtableForm: { airtableUrl },
  } = component;

  return (
    <Section
      id="form"
      sectionStyles={sectionStyles}
      wrapperStyles={wrapperStyles}
    >
      {heading && <h2 className="heading">{heading}</h2>}
      <Form url={airtableUrl} />
      {disclaimer && (
        <div className="disclaimer-wrapper">
          <div className="disclaimer">{renderRichText(disclaimer)}</div>
        </div>
      )}
    </Section>
  );
};

export default AirtableForm;

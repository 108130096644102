import { useState, useEffect } from 'react';

const useFilter = ({
  partners,
  featuredPartners,
  categories,
  filters,
  trades,
}) => {
  const [partnersToFilter, setPartnersToFilter] = useState(partners);
  const [activeCategories, setActiveCategories] = useState([]);
  const [activeFilters, setActiveFilters] = useState([]);
  const [activeTrades, setActiveTrades] = useState([]);
  const [showFeatured, setShowFeatured] = useState(false);
  const [search, setSearch] = useState('');
  const [searchParam, setSearchParam] = useState('');

  // Handles filtering by params
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const paramCategories = params.get('categories')?.split(',');
    const paramFilters = params.get('filters')?.split(',');
    const paramTrades = params.get('trades')?.split(',');

    if (paramCategories) {
      const results = categories?.filter(category => {
        return paramCategories?.includes(category?.slug);
      });
      setActiveCategories(results);
    }

    if (paramFilters) {
      const results = filters?.filter(filter => {
        return paramFilters?.includes(filter?.slug);
      });
      setActiveFilters(results);
    }

    if (paramTrades) {
      const results = trades?.filter(trade => {
        return paramTrades?.includes(trade?.slug);
      });
      setActiveTrades(results);
    }
  }, []);

  // Resets partners if search is empty
  useEffect(() => {
    if (search === '') {
      setPartnersToFilter(partners);
    }
  }, [search]);

  // Handles searching through navigation state
  useEffect(() => {
    if (searchParam) {
      const searchedPartners = partners?.filter(partner => {
        return (
          partner?.name?.toLowerCase().includes(searchParam) ||
          partner?.description?.toLowerCase().includes(searchParam)
        );
      });
      setPartnersToFilter(searchedPartners);
      setSearch(searchParam);
    } else {
      setPartnersToFilter(partners);
    }
  }, [searchParam]);

  // Handles featured filtering
  useEffect(() => {
    if (showFeatured) {
      setPartnersToFilter(featuredPartners);
    } else {
      setPartnersToFilter(partners);
    }
  }, [showFeatured]);

  // All resets
  const resetCategories = () => {
    setActiveCategories([]);
    setActiveFilters(activeFilters);
    setActiveTrades(activeTrades);
    setShowFeatured(showFeatured);
  };

  const resetFilters = () => {
    setActiveCategories(activeCategories);
    setActiveFilters([]);
    setActiveTrades(activeTrades);
    setShowFeatured(showFeatured);
  };

  const resetTrades = () => {
    setActiveCategories(activeCategories);
    setActiveFilters(activeFilters);
    setActiveTrades([]);
    setShowFeatured(showFeatured);
  };

  // Filters partners by categories, filters, and trades
  const filteredPartners = partnersToFilter?.filter(partner => {
    const partnerCategories = partner?.categories;
    const partnerFilters = partner?.filters;
    const partnerTrades = partner?.trades;

    const hasActiveCategories = activeCategories?.some(activeCategory =>
      partnerCategories?.some(category => category?.id === activeCategory?.id)
    );

    const hasActiveFilters = activeFilters?.some(activeFilter =>
      partnerFilters?.some(filter => filter?.id === activeFilter?.id)
    );

    const hasActiveTrades = activeTrades?.some(activeTrade =>
      partnerTrades?.some(trade => trade?.id === activeTrade?.id)
    );

    const hasSearch =
      partner?.name?.toLowerCase()?.includes(search?.toLowerCase()) ||
      partner?.description?.toLowerCase()?.includes(search?.toLowerCase());

    return (
      (hasActiveCategories || activeCategories?.length === 0) &&
      (hasActiveFilters || activeFilters?.length === 0) &&
      (hasActiveTrades || activeTrades?.length === 0) &&
      (hasSearch || search?.length === 0)
    );
  });

  return {
    filteredPartners,
    activeCategories,
    setActiveCategories,
    resetCategories,
    activeFilters,
    setActiveFilters,
    resetFilters,
    activeTrades,
    setActiveTrades,
    resetTrades,
    showFeatured,
    setShowFeatured,
    search,
    setSearch,
    searchParam,
    setSearchParam,
  };
};

export default useFilter;

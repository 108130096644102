import React, { useState, useEffect } from 'react';

import Button from '../Button';
import Badge from '../Badge';

import SearchIcon from './assets/SearchIcon';

import { Container, Wrapper, Input } from './styles/index.styled';

const Search = ({
  setSearch,
  setSearchParam,
  handleKeyDown,
  handleSubmit,
  location,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [badgeValue, setBadgeValue] = useState('');

  const clearSearch = e => {
    e.preventDefault();
    setBadgeValue('');
    setSearchValue('');
    setSearch('');
    setSearchParam('');
  };

  useEffect(() => {
    if (location?.state?.search) {
      setSearchValue(location.state.search);
      setBadgeValue(location.state.search);
    }
  }, [location?.state?.search]);

  return (
    <Container
      className="search"
      onSubmit={e => handleSubmit(e, searchValue, setBadgeValue)}
    >
      <Wrapper>
        <SearchIcon />
        {badgeValue && (
          <Badge
            text={badgeValue}
            classes="search-badge"
            background="bg-gray-100"
          />
        )}
        <Input
          type="text"
          disabled={!!badgeValue}
          placeholder="Integration or Partner Name"
          value={searchValue}
          badgeValue={badgeValue}
          onChange={e => setSearchValue(e.target.value)}
          onKeyDown={e => handleKeyDown(e, searchValue, setBadgeValue)}
        />
      </Wrapper>
      <Button
        as="button"
        theme="tertiary"
        variant="contained"
        size="xl"
        className="clear-btn"
        type="reset"
        onClick={e => clearSearch(e)}
      >
        Clear
      </Button>
      <Button
        as="button"
        theme="primary"
        variant="contained"
        size="xl"
        type="submit"
        onClick={e => handleSubmit(e, searchValue, setBadgeValue)}
      >
        Search
      </Button>
    </Container>
  );
};

export default Search;

import styled, { css } from 'styled-components';
import { font } from '../../../atoms/typography';
import { colors } from '../../../atoms/colors';
import { atMinWidth } from '../../../atoms/breakpoints';

const backgroundMap = {
  White: colors.common.white,
  Gray: colors.gray[25],
  Blue: colors.common.blue7,
  'Dark Gray': colors.common.darkest,
};
const textMap = {
  White: {
    heading: colors.gray[900],
    subhead: colors.gray[600],
  },
  Gray: {
    heading: colors.gray[900],
    subhead: colors.gray[600],
  },
  Blue: {
    heading: colors.common.white,
    subhead: colors.common.white,
  },
  'Dark Gray': {
    heading: colors.common.white,
    subhead: colors.common.white,
  },
};
export const sectionStyles = background => css`
  background-color: ${backgroundMap[background]};
`;
export const wrapperStyles = offsetLayout => css`
  display: flex;
  flex-direction: column;
  gap: 48px;
  ${atMinWidth.xl`
    flex-direction: ${offsetLayout ? 'row' : 'column'};
    gap: 64px;
  `}
`;
export const Header = styled.div`
  width: 100%;
  ${props =>
    props?.offsetLayout &&
    css`
      max-width: 380px;
    `}
  display: flex;
  flex-direction: column;
  gap: 16px;
  ${atMinWidth.xl`
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 0px;
  `}
`;
export const Text = styled.div`
  width: 100%;
  max-width: 670px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  .heading,
  .heading p {
    ${font('display', 'md', '900')}
    color: ${props => textMap[props?.theme].heading};
    ${atMinWidth.xl`
      ${props => css`
        ${font('display', props?.headingSize, '900')}
      `}
    `}
    b {
      color: ${colors.common.skyline};
    }
  }
  .subhead {
    ${font('text', 'lg', '400')}
    color: ${props => textMap[props?.theme].subhead};
  }
`;
const setLayouts = numOfItems => {
  if (numOfItems % 3 === 0) return `repeat(3, 1fr)`;
  return `repeat(2, 1fr)`;
};
export const Grid = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  ${atMinWidth.md`
    display: grid;
    grid-template-columns: ${props => setLayouts(props.numOfItems)};
    gap: 32px 30px;
  `}
  .partner-card .outer-wrapper {
    padding: 0px 24px 24px;
  }
`;

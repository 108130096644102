import React from 'react';

import IconCard from '../../Card/IconCard';
import PartnerCard from '../../Card/PartnerCard';

const CardGenerator = ({ key, component, theme }) => {
  switch (component?.__typename) {
    case 'ContentfulMarketplaceMoleculeCard':
      return <IconCard key={key} component={component} theme={theme} />;
    case 'ContentfulMarketplaceEntityPartner':
      return <PartnerCard key={key} component={component} />;
    default:
      return null;
  }
};

export default CardGenerator;

import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Section from '../../molecules/Section';
import Button from '../../molecules/Button';
import FilterGroup from '../../molecules/FilterGroup/index';
import Search from '../../molecules/Search';

import PartnerCard from '../Card/PartnerCard';
import Pagination from '../../molecules/Pagination';
import SplitCard from '../ConversionPanel/components/SplitCard';

import useCount from './utils/useCount';
import usePartners from '../../hooks/usePartners';
import useSearch from '../../hooks/useSearch';
import useFilter from '../../hooks/useFilter';
import usePagination from '../../hooks/usePagination';
import useWindowSize from '../../hooks/useWindowSize';

import {
  sectionStyles,
  wrapperStyles,
  Sidebar,
  Buttons,
  List,
  Filters,
  Container,
  Header,
  Grid,
  Footer,
} from './styles/index.styled';

const PartnersCardDeck = ({ component, location }) => {
  const { contentfulMoleculeForm } = useStaticQuery(formQuery);
  const [reset, setReset] = useState(false);
  const { width } = useWindowSize();

  const {
    partners,
    categories,
    filters,
    trades,
    featuredPartners,
    featuredCategories,
    featuredFilters,
    featuredTrades,
  } = usePartners({
    partners: component?.partners,
    featuredPartners: component?.featuredPartners,
  });

  const {
    filteredPartners,
    activeCategories,
    setActiveCategories,
    resetCategories,
    activeFilters,
    setActiveFilters,
    resetFilters,
    activeTrades,
    setActiveTrades,
    resetTrades,
    showFeatured,
    setShowFeatured,
    search,
    setSearch,
    setSearchParam,
  } = useFilter({
    partners,
    featuredPartners,
    categories,
    filters,
    trades,
  });

  const postsPerPage = 17;
  const {
    activePosts,
    currentPage,
    nextPage,
    pageNumbers,
    paginate,
    prevPage,
  } = usePagination({
    postsPerPage,
    posts: filteredPartners || [],
    reset,
  });

  const handleReset = () => {
    setReset(true);
    setTimeout(() => setReset(false), 100);
  };

  const { handleKeyDown, handleSubmit } = useSearch(
    search,
    setSearch,
    handleReset
  );

  useEffect(() => {
    paginate(1);
  }, [activeCategories, activeFilters, activeTrades, showFeatured]);

  useEffect(() => {
    if (location?.state?.search) {
      setSearchParam(location?.state?.search);
    }
  }, [location?.state?.search]);

  useEffect(() => {
    if (width < 992) {
      window.scrollTo(0, 0);
    }
  }, [activeCategories, activeFilters, activeTrades, showFeatured, search]);

  const { activePostsCount, activePostsTotal } = useCount(
    partners,
    featuredPartners,
    activePosts,
    filteredPartners,
    activeCategories,
    activeFilters,
    activeTrades,
    search,
    showFeatured,
    currentPage
  );

  return (
    <Section
      id="partners"
      sectionStyles={sectionStyles}
      wrapperStyles={wrapperStyles}
    >
      <Sidebar>
        <p className="heading">
          Showing {activePostsCount} of {activePostsTotal}
        </p>
        <Filters>
          <Buttons>
            <List>
              <Button
                variant="text"
                className={!showFeatured ? 'active' : ''}
                onClick={() => {
                  setShowFeatured(false);
                  handleReset();
                  setActiveCategories([]);
                  setActiveFilters([]);
                  setActiveTrades([]);
                  setSearch('');
                }}
              >
                All
              </Button>
              <Button
                variant="text"
                className={showFeatured ? 'active' : ''}
                onClick={() => {
                  setShowFeatured(true);
                  handleReset();
                }}
              >
                Featured
              </Button>
            </List>
          </Buttons>
          <FilterGroup
            heading="Categories"
            data={partners}
            filters={showFeatured ? featuredCategories : categories}
            activeFilters={activeCategories}
            setActiveFilters={setActiveCategories}
            target="categories"
            reset={() => resetCategories()}
          />
          <FilterGroup
            heading="Filters"
            data={partners}
            filters={showFeatured ? featuredFilters : filters}
            activeFilters={activeFilters}
            setActiveFilters={setActiveFilters}
            target="filters"
            reset={() => resetFilters()}
          />
          <FilterGroup
            heading="Trades"
            data={partners}
            filters={showFeatured ? featuredTrades : trades}
            activeFilters={activeTrades}
            setActiveFilters={setActiveTrades}
            target="trades"
            reset={() => resetTrades()}
          />
        </Filters>
      </Sidebar>
      <Container>
        <Header>
          <p className="heading">
            Showing {activePostsCount} of {activePostsTotal}
          </p>
          <Search
            search={search}
            setSearch={setSearch}
            setSearchParam={setSearchParam}
            handleKeyDown={handleKeyDown}
            handleSubmit={handleSubmit}
            reset={() => handleReset()}
            location={location}
          />
        </Header>
        <Grid>
          {activePosts?.map(partner => (
            <PartnerCard key={partner?.id} component={partner} />
          ))}
          <SplitCard
            {...{
              size: 'sm',
              backgroundColor: 'gray-900',
              heading: 'Don’t see what you’re looking for?',
              subhead: {
                subhead: 'Suggest a new partner for us to collaborate with.',
              },
              button: {
                type: 'Form',
                variant: 'contained',
                color: 'secondary',
                size: 'lg',
                url: '/partner-program',
                label: 'Suggest a Partner',
                form: contentfulMoleculeForm,
              },
            }}
          />
        </Grid>
        <Footer>
          <Pagination
            pageNumbers={pageNumbers}
            paginate={paginate}
            currentPage={currentPage}
            nextPage={nextPage}
            prevPage={prevPage}
          />
        </Footer>
      </Container>
    </Section>
  );
};

export default PartnersCardDeck;

const formQuery = graphql`
  query FormQuery {
    contentfulMarketplaceMoleculeForm(
      contentful_id: { eq: "1oy3aNiQPH9K6XQKgsFGJd" }
    ) {
      ...contentfulMarketplaceMoleculeForm
    }
  }
`;

const truncateText = (string, limit) => {
  if (!string) return '';
  const stringArray = string.split(' ');
  const newStringArray = [];
  for (let index = 0; index < limit; index++) {
    const element = stringArray[index];
    newStringArray.push(element);
  }
  if (newStringArray.slice(-1)[0] === undefined) {
    return newStringArray.join(' ');
  }
  return `${newStringArray.join(' ')}...`;
};

export default truncateText;

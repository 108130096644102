import styled, { css } from 'styled-components';
import { font } from '../../../atoms/typography';
import { colors } from '../../../atoms/colors';
import { atMinWidth } from '../../../atoms/breakpoints';

export const sectionStyles = css`
  ${atMinWidth.xl`
  padding: 72px 0px;
`}
`;
export const wrapperStyles = css`
  display: flex;
  flex-direction: column-reverse;
  gap: 30px;
  ${atMinWidth.xl`
  display: grid;
  grid-template-columns: 300px 1fr;
`}
`;
export const Sidebar = styled.aside`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  .heading {
    ${font('text', 'lg', '900')}
    color: ${colors.gray[900]};
    margin-top: 32px;
  }
`;
export const Buttons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid ${colors.gray[100]};
  .heading {
    ${font('text', 'xl', '900')}
    color: ${colors.gray[900]};
  }
`;
export const List = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;

  button {
    width: 100%;
    border-right: 2px solid ${colors.common.transparent};
    &.active {
      border-right: 2px solid ${colors.common.titanBlue};
    }
  }
`;
export const Filters = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${colors.gray[25]};
  padding: 24px;
  gap: 24px;
  .filter-group {
    padding-bottom: 24px;
    border-bottom: 1px solid ${colors.gray[100]};
    &:last-of-type {
      padding-bottom: 0px;
      border-bottom: none;
    }
  }
`;
export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;

  gap: 32px;
  .heading {
    ${font('text', 'lg', '900')}
    color: ${colors.gray[900]};
    ${atMinWidth.xl`
      display: none;
    `}
  }
  ${atMinWidth.md`
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  `}
  ${atMinWidth.xl`
    justify-content: flex-end;
  `}
  .search {
    max-width: 100%;
    padding-top: 4px;
    padding-bottom: 4px;
    ${atMinWidth.md`
    max-width: 476px;
  `}
  }
`;
export const Grid = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: hidden;
  ${atMinWidth.md`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`}
  ${atMinWidth.xl`
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
`}
`;
export const Footer = styled.div`
  width: 100%;
  padding-top: 24px;
  border-top: 1px solid ${colors.gray[50]};
`;

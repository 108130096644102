import styled from 'styled-components';

export const Button = styled.button`
  width: 24px;
  height: 24px;
  margin: 0px 8px;
  padding: 0px;
  cursor: pointer;
  transition: all 300ms ease-in-out;
  background: none;
  outline: none;
  border: none;
  color: var(--darkest);
  &:hover,
  &.active {
    color: #ffffff;
    background: var(--darkest);
  }
`;

import styled, { css } from 'styled-components';
import { colors } from '../../../atoms/colors';

export const sectionStyles = css`
  background: ${colors.primary[25]};
`;
export const wrapperStyles = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
export const Carousel = styled.div`
  position: relative;
  display: block;
  width: 100%;
  .slick-list {
    overflow: hidden;
  }
  .slick-track {
    display: flex !important;
  }
  .slick-slide {
    height: inherit !important;
  }
  .slick-slide > div {
    height: 100%;
  }
`;
export const Controls = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

import styled from 'styled-components';
import { font } from '../../../atoms/typography';
import { colors } from '../../../atoms/colors';
import { atMinWidth } from '../../../atoms/breakpoints';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 478px;
  display: flex;
  flex-direction: column-reverse;
  ${atMinWidth.xl`
  display: grid;
  grid-template-columns: 1fr 470px;
`}
`;
export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px;
  ${atMinWidth.md`
  padding: 64px;
`}
  background: ${colors.primary[900]};
  ${atMinWidth.xl`
  padding: 0px;
`}
`;
export const Text = styled.div`
  width: 100%;
  max-width: 570px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  ${atMinWidth.md`
  gap: 32px;
`}
  .quote {
    ${font('display', 'xs', '800')}
    color: ${colors.common.white};
    ${atMinWidth.md`
    ${font('display', 'md', '800')}
  `}
  }
`;
export const Author = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  .name {
    ${font('text', 'lg', 900)}
    color: ${colors.common.white};
  }
  .author-details {
    display: flex;
  }
  .role {
    ${font('text', 'lg', 400)}
    color: #E9D7FE;
  }
  .company {
    ${font('text', 'lg', 400)}
    color: #E9D7FE;
  }
`;
export const Image = styled.div`
  width: 100%;
  max-height: 208px;
  ${atMinWidth.md`
    max-height: 320px;
  `}
  ${atMinWidth.xl`
  max-height: 100%;
`}
  .gatsby-image {
    width: 100%;
    height: 100%;
  }
`;
export const Dots = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  ${atMinWidth.xl`
  justify-content: flex-start;
`}
  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    padding: 0px;
    margin: 0px;
    background: ${colors.primary[200]};
    &.active {
      background: ${colors.common.white};
    }
  }
`;

const checkNumber = (
  pageNumber,
  currentPage,
  pageNumbers,
  prevPage,
  nextPage,
  isPrevPage
) => {
  const currentPageNumber = currentPage === pageNumber;
  const prevPageNumber = prevPage === pageNumber && isPrevPage;
  const nextPageNumber = nextPage === pageNumber;
  const lastPageNumber = pageNumber === pageNumbers.length;
  return !(
    !(currentPageNumber || prevPageNumber || nextPageNumber) || lastPageNumber
  );
};

export default checkNumber;

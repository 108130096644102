import React from 'react';
import { colors } from '../../../atoms/colors';

const QuoteIcon = () => (
  <svg
    width="56"
    height="43"
    viewBox="0 0 56 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M48.0211 0C41.2243 5.61512 25.2665 24.0859 25.2665 43H42.1108C42.1108 23.0515 49.7942 13.1512 56 4.58076L48.0211 0ZM22.7546 0C15.9578 5.61512 0 24.0859 0 43H16.8443C16.8443 23.0515 24.5277 13.1512 30.7335 4.58076L22.7546 0Z"
      fill={colors.common.titanBlue}
    />
  </svg>
);

export default QuoteIcon;

import styled, { css } from 'styled-components';

import { colors } from '../../../atoms/colors';
import { font } from '../../../atoms/typography';
import { atMinWidth } from '../../../atoms/breakpoints';

export const backgroundMap = {
  Light: colors.common.white,
  Dark: colors.common.darkest,
};
export const textMap = {
  Light: colors.common.darkest,
  Dark: colors.common.white,
};
export const sectionStyles = theme => css`
  background-color: ${backgroundMap[theme]};
`;
export const wrapperStyles = css`
  && {
    max-width: 894px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
  }
`;
export const Text = styled.div`
  &,
  & p {
    ${font('display', 'xs', '900')};
    color: ${props => textMap[props.theme]};
    text-align: center;
    ${atMinWidth.sm`
        ${font('display', 'sm', '900')};
      `}
    ${atMinWidth.md`
        ${font('display', 'md', '900')};
      `}
      ${atMinWidth.lg`
        ${font('display', 'lg', '900')};
      `}
      b {
      ${font('display', 'xs', '900')};
      color: ${colors.common.titanBlue};
      ${atMinWidth.sm`
          ${font('display', 'sm', '900')};
        `}
      ${atMinWidth.md`
          ${font('display', 'md', '900')};
        `}
        ${atMinWidth.lg`
          ${font('display', 'lg', '900')};
        `}
    }
  }
`;
export const Author = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  .name {
    ${font('text', 'lg', '900')};
    color: ${props => textMap[props.theme]};
  }
  .details {
    ${font('text', 'lg', '400')};
    color: ${props =>
      props?.theme === 'Light' ? colors.gray[500] : colors.gray[200]};
  }
`;

import styled from 'styled-components';
import { font } from '../../../atoms/typography';
import { colors } from '../../../atoms/colors';

const backgroundMap = {
  White: colors.gray[25],
  Gray: colors.common.white,
  Blue: colors.common.titanBlue,
  'Dark Gray': colors.gray[800],
};
const sizeMap = {
  icon: {
    sm: '48px',
    lg: '96px',
  },
  heading: {
    sm: font('display', 'xs', '900'),
    lg: font('text', 'xl', '900'),
  },
  subhead: {
    sm: font('text', 'xl', '400'),
    lg: font('text', 'lg', '400'),
  },
  padding: {
    sm: '40px',
    lg: '24px',
  },
  gap: {
    sm: '32px',
    lg: '24px',
  },
};
const textMap = {
  White: {
    heading: colors.gray[900],
    subhead: colors.gray[600],
  },
  Gray: {
    heading: colors.gray[900],
    subhead: colors.gray[600],
  },
  Blue: {
    heading: colors.common.white,
    subhead: colors.common.white,
  },
  'Dark Gray': {
    heading: colors.common.white,
    subhead: colors.primary[50],
  },
};
export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${props => sizeMap.gap[props.size]};
  padding: ${props => sizeMap.padding[props.size]};
  background-color: ${props => backgroundMap[props.theme]};

  .gatsby-image-wrapper,
  img {
    width: ${props => sizeMap.icon[props.size]};
    height: ${props => sizeMap.icon[props.size]};
  }
`;
export const Text = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  .heading {
    ${props => sizeMap.heading[props.size]}
    color: ${props => textMap[props.theme].heading};
  }
  .subhead {
    ${props => sizeMap.subhead[props.size]}
    color: ${props => textMap[props.theme].subhead};
  }
`;

import React from 'react';

import Image from '../Image';
import Badge from '../../molecules/Badge';
import BadgeGroup from '../../molecules/BadgeGroup';

import truncateText from '../../utils/truncateText';
import useSlideSwipe from '../../hooks/useSlideSwipe';

import {
  Container,
  OuterWrapper,
  Wrapper,
  Tier,
  Icon,
  Text,
  Button,
} from './styles/PartnerCard.styled';

const PartnerCard = ({ component }) => {
  const { handleClick, handleMouseDown } = useSlideSwipe();

  if (!component) return null;
  const {
    name,
    slug,
    tier,
    icon,
    tagline,
    categories,
    filters,
    disableClickableView,
  } = component;

  return (
    <Container className="partner-card" disabled={disableClickableView}>
      {slug && !disableClickableView && (
        <Button
          type="button"
          aria-label="Card Button"
          className="clickable"
          onMouseDown={e => handleMouseDown(e)}
          onClick={e => handleClick(e, `/partner/${slug}`)}
        />
      )}
      <Tier
        backgroundColor={tier?.backgroundColor || '#ffffff'}
        textColor={tier?.textColor || '#ffffff'}
      >
        <Badge text={tier?.name} classes="card-badge" size="lg" />
      </Tier>
      <OuterWrapper className="outer-wrapper">
        <Wrapper>
          {icon && (
            <Icon>
              <Image src={icon?.gatsbyImageData} alt={icon?.title} />
            </Icon>
          )}
          {name || tagline ? (
            <Text>
              {name && <p className="heading">{name}</p>}
              {tagline && (
                <p className="subhead">{truncateText(tagline, 15)}</p>
              )}
            </Text>
          ) : null}
        </Wrapper>
        {categories || filters ? (
          <BadgeGroup>
            {categories?.map(category => (
              <Badge
                key={category?.id}
                text={category?.name}
                classes="card-tag"
                background="bg-gray-100"
                color="darkest"
                clickable
                url={`/partner?categories=${category?.slug}`}
              />
            ))}
            {filters?.map(filter => (
              <Badge
                key={filter?.id}
                text={filter?.name}
                classes="card-tag"
                background="bg-skyline-1"
                color="titanBlue"
                clickable
                url={`/partner?filters=${filter?.slug}`}
              />
            ))}
          </BadgeGroup>
        ) : null}
      </OuterWrapper>
    </Container>
  );
};

export default PartnerCard;

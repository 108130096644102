import React from 'react';
import shortid from 'shortid';

import AirtableForm from '../components/AirtableForm';
import CardCarousel from '../components/CardCarousel';
import CardDeck from '../components/CardDeck';
import ConversionPanel from '../components/ConversionPanel';
import Hero from '../components/Hero';
import PartnersCardDeck from '../components/PartnersCardDeck/index';
import Quote from '../components/Quote';
import Showcase from '../components/Showcase';
import TestimonialCarousel from '../components/TestimonialCarousel';

const componentGenerator = (components, location) =>
  components?.map(component => {
    switch (component?.__typename) {
      case 'ContentfulMarketplaceComponentAirtableForm':
        return (
          <AirtableForm
            component={component}
            location={location}
            key={shortid.generate()}
          />
        );
      case 'ContentfulMarketplaceComponentCardDeck':
        return (
          <CardDeck
            component={component}
            location={location}
            key={shortid.generate()}
          />
        );
      case 'ContentfulMarketplaceComponentCardCarousel':
        return (
          <CardCarousel
            component={component}
            location={location}
            key={shortid.generate()}
          />
        );
      case 'ContentfulMarketplaceComponentConversionPanel':
        return (
          <ConversionPanel
            component={component}
            location={location}
            key={shortid.generate()}
          />
        );
      case 'ContentfulMarketplaceComponentHero':
        return (
          <Hero
            component={component}
            location={location}
            key={shortid.generate()}
          />
        );
      case 'ContentfulMarketplaceComponentPartnersCardDeck':
        return (
          <PartnersCardDeck
            component={component}
            location={location}
            key={shortid.generate()}
          />
        );
      case 'ContentfulMarketplaceComponentQuote':
        return (
          <Quote
            component={component}
            location={location}
            key={shortid.generate()}
          />
        );
      case 'ContentfulMarketplaceComponentTestimonialCarousel':
        return (
          <TestimonialCarousel
            component={component}
            location={location}
            key={shortid.generate()}
          />
        );
      case 'ContentfulMarketplaceComponentShowcase':
        return (
          <Showcase
            component={component}
            location={location}
            key={shortid.generate()}
          />
        );
      default:
    }
  });

export default componentGenerator;

import { css } from 'styled-components';

import { font } from '../../../atoms/typography';
import { colors } from '../../../atoms/colors';
import { atMinWidth } from '../../../atoms/breakpoints';

export const sectionStyles = css`
  background-color: #fafafa;
  ${atMinWidth.xs`
    padding-top: 24px;
  `}
`;
export const wrapperStyles = css`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  .heading {
    position: absolute;
    background-color: rgb(250, 250, 250);
    z-index: 100;
    top: 0px;
    width: 100%;
    min-height: 110px;
    text-align: center;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    ${font('display', 'sm', '900')}
    color: ${colors.gray[900]};
    ${atMinWidth.sm`
      ${font('display', 'md', '900')}
    `}
    ${atMinWidth.lg`
      ${font('display', 'lg', '900')}
      min-height: 140px;
    `}
  }
  .disclaimer-wrapper {
    width: 100%;
    position: absolute;
    background-color: rgb(250, 250, 250);
    z-index: 100;
    bottom: 0px;
    width: 100%;
    min-height: 110px;
    text-align: center;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    .disclaimer p {
      max-width: calc(100% - 40px);
      ${font('text', 'sm', '400')}
      color: ${colors.gray[500]};
      margin: 0px auto;
      ${atMinWidth.lg`
        max-width: 770px;
        ${font('text', 'md', '400')}
      `}
    }
    a {
      color: ${colors.primary[600]};
    }
  }
`;

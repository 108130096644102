import React, { useRef } from 'react';
import Slider from 'react-slick';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

import Section from '../../molecules/Section';
import Button from '../../molecules/Button';
import PartnerCard from '../Card/PartnerCard';

import {
  sectionStyles,
  wrapperStyles,
  Header,
  Text,
  Carousel,
  Controls,
} from './styles/index.styled';

const sliderSettings = {
  dots: false,
  arrows: false,
  infinite: true,
  centerMode: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  variableWidth: true,
};

const CardCarousel = ({ component }) => {
  const sliderRef = useRef(null);

  if (!component) return null;
  const { background, heading, subhead, button, cards } = component;

  return (
    <Section
      sectionStyles={sectionStyles(background)}
      wrapperStyles={wrapperStyles}
    >
      {heading || subhead?.subhead ? (
        <Header>
          <Text>
            {heading && <h2 className="heading">{heading}</h2>}
            {subhead?.subhead && <p className="subhead">{subhead.subhead}</p>}
          </Text>
          {button?.url && (
            <Button
              variant={button?.variant}
              theme={button?.color}
              size={button?.size}
              href={button?.url}
            >
              {button?.label}
            </Button>
          )}
        </Header>
      ) : null}
      <Carousel>
        {cards?.length > 0 && (
          <Slider {...sliderSettings} ref={sliderRef}>
            {cards?.map(card => (
              <PartnerCard key={card?.id} component={card} />
            ))}
          </Slider>
        )}
      </Carousel>
      <Controls>
        <Button
          variant="text"
          startIcon={<FiChevronLeft size="20px" />}
          onClick={() => {
            sliderRef.current.slickPrev();
          }}
        >
          Previous
        </Button>
        <Button
          variant="text"
          endIcon={<FiChevronRight size="20px" />}
          onClick={() => {
            sliderRef.current.slickNext();
          }}
        >
          Next
        </Button>
      </Controls>
    </Section>
  );
};

export default CardCarousel;

import styled from 'styled-components';

export const Container = styled.div`
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
`;
export const Elipsis = styled.div`
  width: auto;
  height: auto;
  margin: 0px 8px;
`;

import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

import Section from '../../molecules/Section';
import Button from '../../molecules/Button';

import TestimonialCarouselCard from '../Card/TestimonialCarouselCard';

import {
  sectionStyles,
  wrapperStyles,
  Carousel,
  Controls,
} from './styles/index.styled';

const sliderSettings = setActiveSlideIndex => ({
  dots: false,
  arrows: false,
  infinite: true,
  centerMode: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  afterChange: index => setActiveSlideIndex(index),
});

const TestimonialCarousel = ({ component }) => {
  const sliderRef = useRef(null);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  if (!component) return null;
  const { testimonials } = component;
  const showControls = testimonials?.length > 1;

  return (
    <Section sectionStyles={sectionStyles} wrapperStyles={wrapperStyles}>
      <Carousel>
        <Slider ref={sliderRef} {...sliderSettings(setActiveSlideIndex)}>
          {testimonials.map(slide => (
            <TestimonialCarouselCard
              key={slide?.id}
              slides={testimonials}
              sliderRef={sliderRef}
              setActiveSlideIndex={setActiveSlideIndex}
              activeSlideIndex={activeSlideIndex}
              showControls={showControls}
              {...slide}
            />
          ))}
        </Slider>
      </Carousel>
      {showControls && (
        <Controls>
          <Button
            variant="text"
            startIcon={<FiChevronLeft size="20px" />}
            textColor="primary-900"
            onClick={() => {
              sliderRef.current.slickPrev();
            }}
          >
            Previous
          </Button>
          <Button
            variant="text"
            endIcon={<FiChevronRight size="20px" />}
            textColor="primary-900"
            onClick={() => {
              sliderRef.current.slickNext();
            }}
          >
            Next
          </Button>
        </Controls>
      )}
    </Section>
  );
};

export default TestimonialCarousel;

import React from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

import Section from '../../molecules/Section';
import Button from '../../molecules/Button';

import CardGenerator from './utils/CardGenerator';

import {
  sectionStyles,
  wrapperStyles,
  Header,
  Text,
  Grid,
} from './styles/index.styled';

const CardDeck = ({ component }) => {
  if (!component) return null;
  const {
    background,
    offsetLayout,
    headingSize,
    richTextHeading,
    subhead,
    button,
    cards,
  } = component;

  return (
    <Section
      sectionStyles={sectionStyles(background)}
      wrapperStyles={wrapperStyles(offsetLayout)}
    >
      {richTextHeading || subhead?.subhead ? (
        <Header offsetLayout={offsetLayout}>
          <Text theme={background} headingSize={headingSize}>
            {richTextHeading && (
              <div className="heading">{renderRichText(richTextHeading)}</div>
            )}
            {subhead?.subhead && <p className="subhead">{subhead.subhead}</p>}
          </Text>
          {button?.url && (
            <Button
              variant={button?.variant}
              theme={button?.color}
              size={button?.size}
              href={button?.url}
            >
              {button?.label}
            </Button>
          )}
        </Header>
      ) : null}
      {cards?.length > 0 && (
        <Grid numOfItems={cards?.length}>
          {cards?.map(card => (
            <CardGenerator
              key={card?.id}
              component={card}
              theme={background || 'Light'}
            />
          ))}
        </Grid>
      )}
    </Section>
  );
};

export default CardDeck;

import React from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

import Section from '../../molecules/Section';

import QuoteIcon from './assets/QuoteIcon';

import {
  sectionStyles,
  wrapperStyles,
  Text,
  Author,
} from './styles/index.styled';

const Quote = ({ component }) => {
  if (!component) return null;
  const {
    id,
    theme,
    quote,
    author: { name, role, company },
  } = component;

  return (
    <Section
      id={id}
      sectionStyles={sectionStyles(theme)}
      wrapperStyles={wrapperStyles}
    >
      <QuoteIcon />
      {quote && (
        <Text theme={theme} className="quote">
          {renderRichText(quote)}
        </Text>
      )}
      {name && (
        <Author theme={theme}>
          <p className="name">{name}</p>
          <p className="details">
            {company} {role}
          </p>
        </Author>
      )}
    </Section>
  );
};

export default Quote;

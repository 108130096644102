import React from 'react';

import styled from 'styled-components';
import { font } from '../../atoms/typography';
import { colors } from '../../atoms/colors';

import Checked from './assets/checked-icon.svg';
import Unchecked from './assets/unchecked-icon.svg';

const FilterGroup = ({
  heading,
  filters,
  activeFilters,
  setActiveFilters,
  reset,
}) => {
  const handleFilter = filter => {
    reset();
    const filterActive = activeFilters.filter(f => f?.id === filter?.id);
    if (filterActive?.length > 0) {
      setActiveFilters([]);
    } else {
      setActiveFilters([filter]);
    }
  };

  if (!filters || filters?.length === 0) return null;
  return (
    <Container className="filter-group">
      {heading && <p className="heading">{heading}</p>}
      {filters && (
        <List>
          {filters?.map(filter => {
            const isActive = activeFilters.filter(f => f?.id === filter?.id);
            return (
              <ListItem key={filter?.id}>
                <Button
                  type="button"
                  onClick={() => {
                    handleFilter(filter);
                  }}
                >
                  <Text>
                    <Icon>
                      {isActive?.length > 0 ? (
                        <img src={Checked} alt="Checked Icon" />
                      ) : (
                        <img src={Unchecked} alt="Unchecked Icon" />
                      )}
                    </Icon>
                    {filter?.name}
                  </Text>
                  {filter?.count && <Count>{filter.count}</Count>}
                </Button>
              </ListItem>
            );
          })}
        </List>
      )}
    </Container>
  );
};

export default FilterGroup;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  .heading {
    ${font('text', 'xl', '900')}
    color: ${colors.gray[900]};
  }
`;
const List = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0px;
  padding: 0px;
  list-style: none;
`;
const ListItem = styled.li`
  width: 100%;
`;
const Button = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  outline: none;
  border: none;
  background: ${colors.common.transparent};
`;
const Text = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  gap: 12px;
  ${font('text', 'md', '800')}
  color: ${colors.gray[900]};
  text-align: left;
`;
const Icon = styled.div`
  width: 16px;
  height: 16px;
  transform: translateY(-1.5px);
`;
const Count = styled.p`
  padding: 2px 10px;
  background-color: #e8f4fe;
  ${font('text', 'sm', '500')}
  color: ${colors.common.titanBlue};
`;

import styled from 'styled-components';
import { font } from '../../../atoms/typography';
import { colors } from '../../../atoms/colors';

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: ${colors.common.white};
  border: 1px solid ${colors.gray[50]};
  overflow: hidden;
  transition: all 300ms ease-in-out;

  &:hover {
    box-shadow: rgb(16 24 40 / 10%) 0px 12px 16px -4px,
      rgb(16 24 40 / 5%) 0px 4px 6px -2px;
  }

  ${({ disabled }) => disabled && `&:hover { box-shadow: none; }`}
`;
export const OuterWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
  padding: 24px;
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
export const Tier = styled.div`
  width: fit-content;
  align-self: flex-end;
  padding-top: 24px;
  && .card-badge {
    min-height: 34px;
    background-color: ${props => props.backgroundColor};
    color: ${props => props.textColor};
    ${font('text', 'xs', '800')}
  }
`;
export const Icon = styled.div`
  display: flex;
  max-width: 125px;
  .gatsby-image {
    width: fit-content;
    height: 100%;
    img {
      width: 100%;
      height: 48px;
      object-fit: contain !important;
    }
  }
`;
export const Text = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  .heading {
    ${font('text', 'xl', '900')}
    color: ${colors.gray[900]};
  }
  .subhead {
    ${font('text', 'md', '400')}
    color: ${colors.gray[600]};
  }
`;
export const Button = styled.button`
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background: ${colors.common.transparent};
  cursor: pointer;
`;

import styled, { css } from 'styled-components';
import { font } from '../../../atoms/typography';
import { colors } from '../../../atoms/colors';
import { atMinWidth } from '../../../atoms/breakpoints';

const backgroundMap = {
  White: colors.common.white,
  Gray: colors.gray[25],
};
export const sectionStyles = background => css`
  background-color: ${backgroundMap[background]};
  overflow: hidden;
`;
export const wrapperStyles = css`
  display: flex;
  flex-direction: column;
  gap: 64px;
  overflow: hidden;
  ${atMinWidth.md`
      gap: 80px;
    `}
`;
export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  ${atMinWidth.xl`
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      gap: 0px;
    `}
`;
export const Text = styled.div`
  width: 100%;
  max-width: 770px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  .heading {
    ${font('display', 'md', '900')}
    color: ${colors.gray[900]};
  }
  .subhead {
    ${font('text', 'lg', '400')}
    color: ${colors.gray[600]};
  }
`;
export const Carousel = styled.div`
  position: relative;
  display: block;
  width: 100%;
  .slick-list {
    overflow: visible;
  }
  .slick-track {
    display: flex !important;
    gap: 20px;
    transform
  }
  .slick-slide {
    height: inherit !important;
  }
  .slick-slide > div {
    height: 100%;
  }
  .partner-card {
    max-width: 264px;
    height: 100%;
  }
`;
export const Controls = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    color: ${colors.gray[900]};
  }
`;

import React from 'react';
import shortid from 'shortid';

import GatsbyImage from '../Image';
import Button from '../../molecules/Button';

import {
  Container,
  Wrapper,
  Text,
  Author,
  Dots,
  Image,
} from './styles/TestimonialCarouselCard.styled';

const TestimonialCarouselCard = ({
  slides,
  sliderRef,
  setActiveSlideIndex,
  activeSlideIndex,
  showControls,
  ...props
}) => {
  const { quote, image, author } = props;

  return (
    <Container>
      <Wrapper>
        <Text>
          {quote?.quote && (
            <blockquote className="quote">{quote.quote}</blockquote>
          )}
          {author?.name && (
            <Author className="author">
              {author?.name && <p className="name">— {author.name}</p>}
              <div className="author-details">
                {author?.role && <p className="role">{author.role}</p>}
                {author?.company && (
                  <p className="company">, {author.company}</p>
                )}
              </div>
            </Author>
          )}
          {showControls && (
            <Dots>
              {slides.map((_, index) => (
                <Button
                  key={shortid.generate()}
                  className={`dot ${
                    activeSlideIndex === index ? 'active' : ''
                  }`}
                  variant="text"
                  onClick={() => {
                    sliderRef.current.slickGoTo(index);
                    setActiveSlideIndex(index);
                  }}
                />
              ))}
            </Dots>
          )}
        </Text>
      </Wrapper>
      {image?.gatsbyImageData && (
        <Image>
          <GatsbyImage src={image.gatsbyImageData} alt={image?.title} />
        </Image>
      )}
    </Container>
  );
};

export default TestimonialCarouselCard;

import { useState, useEffect } from 'react';

const useCount = (
  partners,
  featuredPartners,
  activePosts,
  filteredPartners,
  activeCategories,
  activeFilters,
  activeTrades,
  search,
  showFeatured,
  currentPage
) => {
  const [activePostsTotal, setActivePostsTotal] = useState(activePosts?.length);
  const [activePostsCount, setActivePostsCount] = useState(0);

  useEffect(() => {
    if (
      activeCategories.length === 0 &&
      activeFilters.length === 0 &&
      activeTrades.length === 0 &&
      search === ''
    ) {
      setActivePostsTotal(partners?.length);
    } else {
      setActivePostsTotal(filteredPartners?.length);
    }
  }, [activeCategories, activeFilters, activeTrades, search]);

  useEffect(() => {
    const partnerCards = document.querySelectorAll('.partner-card');
    const partnerCardsLength = partnerCards?.length;
    const cardsPerPage = 17;
    const difference = cardsPerPage - partnerCardsLength;

    if (
      activeCategories.length === 0 ||
      activeFilters.length === 0 ||
      activeTrades.length === 0 ||
      search === ''
    ) {
      if (partnerCardsLength < cardsPerPage) {
        setActivePostsCount(cardsPerPage * currentPage - difference);
      } else {
        setActivePostsCount(cardsPerPage * currentPage);
      }
    } else if (partnerCardsLength < cardsPerPage) {
      setActivePostsCount(cardsPerPage * currentPage - difference);
    } else {
      setActivePostsCount(cardsPerPage * currentPage);
    }
  }, [currentPage, activeCategories, activeFilters, activeTrades, search]);

  useEffect(() => {
    if (showFeatured) {
      setActivePostsCount(featuredPartners?.length);
    } else {
      setActivePostsCount(17);
    }
  }, [showFeatured]);

  return {
    activePostsTotal,
    activePostsCount,
  };
};

export default useCount;

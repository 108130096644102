import styled, { css } from 'styled-components';
import { font } from '../../../atoms/typography';
import { colors } from '../../../atoms/colors';
import { atMinWidth } from '../../../atoms/breakpoints';

export const wrapperStyles = css`
  display: flex;
  flex-direction: column;
  gap: 48px;
  ${atMinWidth.xl`
    gap: 64px;
  `};
`;
export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 48px;
  ${atMinWidth.xl`
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 100px;
  `};
`;
export const Text = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  ${atMinWidth.xl`
    max-width: 500px;
  `};
  .heading {
    ${font('display', 'md', '900')}
    color: ${colors.gray[900]};
  }
  .subhead {
    ${font('text', 'lg', '400')}
    color: ${colors.gray[600]};
  }
`;
export const Grid = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  ${atMinWidth.xl`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  `}
`;
export const SearchBar = styled.div`
  width: 100%;
  ${atMinWidth.xl`
    width: 50%;
  `}
`;

import React from 'react';
import { Button } from '../styles/PaginationButton.styled';

const PaginationButton = ({ direction, label, active, icon, clickEvent }) => (
  <Button
    type="button"
    className={`pagination-btn pagination-${direction} ${
      active && 'active'
    } text-md-medium darkest`}
    onClick={clickEvent}
  >
    {direction === 'prev' && icon}
    {label}
    {direction === 'next' && icon}
  </Button>
);

export default PaginationButton;

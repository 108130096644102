import React from 'react';
import { Button } from '../styles/PaginationNumber.styled';

const PaginationNumber = ({ label, active, clickEvent }) => (
  <Button
    type="button"
    onClick={clickEvent}
    className={`${active && 'active'} text-md-bold gray-900`}
  >
    {label}
  </Button>
);

export default PaginationNumber;

import React from 'react';
import PaginationButton from './components/PaginationButton';
import PaginationNumbers from './components/PaginationNumbers';
import PrevIcon from './assets/PrevIcon';
import NextIcon from './assets/NextIcon';
import scrollToTop from './utils/scrollToTop';
import { Container } from './styles/index.styled';

const Pagination = ({
  pageNumbers,
  paginate,
  currentPage,
  nextPage,
  prevPage,
}) => {
  if (pageNumbers?.length <= 1) return null;

  return (
    <Container className="pagination">
      <PaginationButton
        direction="prev"
        label="Previous"
        active={prevPage !== 0}
        icon={<PrevIcon />}
        clickEvent={() => {
          paginate(currentPage - 1);
          scrollToTop();
        }}
      />
      <PaginationNumbers
        pageNumbers={pageNumbers}
        paginate={paginate}
        currentPage={currentPage}
        nextPage={nextPage}
        prevPage={prevPage}
        isPrevPage={prevPage !== 0}
      />
      <PaginationButton
        direction="next"
        label="Next"
        active={!(currentPage + 1 > pageNumbers.length)}
        icon={<NextIcon />}
        clickEvent={() => {
          paginate(currentPage + 1);
          scrollToTop();
        }}
      />
    </Container>
  );
};

export default Pagination;

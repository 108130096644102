const useSearch = (search, setSearch, handleReset) => {
  const handleKeyDown = e => {
    if (e?.key === 'Enter' || e.keyCode === 13) {
      handleReset();
      setSearch(e.target.value);
    }
  };

  const handleSubmit = (e, searchValue, setBadgeValue) => {
    e?.preventDefault();
    handleReset();
    setBadgeValue(searchValue);
    setSearch(searchValue);
  };

  return { handleKeyDown, handleSubmit };
};

export default useSearch;

import { useState, useEffect } from 'react';

const usePagination = ({ postsPerPage, posts = [], reset }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const prevPage = currentPage - 1;
  const nextPage = currentPage + 1;
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const activePosts = posts?.slice(indexOfFirstPost, indexOfLastPost);
  const pageNumbersIndexes = Math.ceil(posts.length / postsPerPage);
  const pageNumbers = Array.from(Array(pageNumbersIndexes).keys());

  const paginate = pageNumber => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [reset]);

  return {
    activePosts,
    pageNumbers,
    paginate,
    currentPage,
    nextPage,
    prevPage,
  };
};

export default usePagination;

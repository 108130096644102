import React from 'react';
import Image from '../../components/Image';
import { Frame, Icon, Text } from './styles/index.styled';

const ShowcaseItem = ({ heading, subhead, icon }) => (
  <Frame>
    {icon && (
      <Icon>
        <Image
          src={icon?.gatsbyImageData || icon?.file?.url}
          alt={icon?.title}
        />
      </Icon>
    )}
    <Text>
      {heading && <p className="heading">{heading}</p>}
      {subhead?.subhead && <p className="subhead">{subhead.subhead}</p>}
    </Text>
  </Frame>
);

export default ShowcaseItem;

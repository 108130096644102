import styled from 'styled-components';
import { font } from '../../../atoms/typography';
import { colors } from '../../../atoms/colors';
import { atMinWidth } from '../../../atoms/breakpoints';

export const Container = styled.form`
  width: 100%;
  padding: 8px 16px 8px 8px;
  display: flex;
  background-color: ${colors.common.white};
  border: 1px solid ${colors.gray[50]};
  .clear-btn {
    box-shadow: none;
    color: ${colors.common.darkest};
    font-weight: 600;
    &:hover {
      opacity: 0.75;
      background-color: ${colors.common.transparent};
      border: 1px solid ${colors.common.transparent};
    }
  }
`;
export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
  padding-left: 16px;

  .search-badge {
    position: absolute;
    left: 54px;
    top: 50%;
    transform: translateY(-50%);
  }
`;
export const Input = styled.input`
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
  outline: none;
  border: none;
  ${font('text', 'xl', '400')}
  color: ${colors.gray[400]};
  ${props => props.badgeValue !== '' && `color: ${colors.common.transparent};`}
  &::placeholder {
    ${font('text', 'md', '400')}
    color: ${colors.gray[400]};
    ${atMinWidth.sm`
      ${font('text', 'lg', '400')}
    `}
    ${props =>
      props.badgeValue !== '' && `color: ${colors.common.transparent};`}
  }
  &:disabled {
    background-color: ${colors.common.transparent};
  }
`;

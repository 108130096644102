import styled from 'styled-components';

export const Button = styled.button`
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  transition: all 300ms ease-in-out;
  visibility: hidden;
  color: var(--darkest);
  &.active {
    visibility: visible;
  }
  &:hover {
    color: var(--gray-900);
    svg path {
      stroke: var(--gray-900);
    }
  }
  &.pagination-btn svg path {
    transition: all 300ms ease-in-out;
  }
  &.pagination-prev svg {
    margin-right: 21px;
    transform: translateY(-1px);
  }
  &.pagination-next svg {
    margin-left: 21px;
    transform: translateY(-1px);
  }
`;

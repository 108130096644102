import React from 'react';

import Image from '../Image';

import { Container, Text } from './styles/IconCard.styled';

const IconCard = ({ component, theme }) => {
  if (!component) return null;
  const {
    heading,
    subhead: { subhead },
    iconSize,
    icon: {
      title,
      gatsbyImageData,
      file: { url },
    },
  } = component;
  const size = iconSize || 'sm';

  return (
    <Container size={size} theme={theme}>
      <Image src={gatsbyImageData || url} alt={title} />
      <Text size={size} theme={theme}>
        {heading && <p className="heading">{heading}</p>}
        {subhead && <p className="subhead">{subhead}</p>}
      </Text>
    </Container>
  );
};

export default IconCard;
